.shape_one {
    border-radius: 40% / 100px !important;
}

.shape_two {
    border-radius: 80% / 100px !important;
}

.shape_three {
    transform: skew(-10deg) !important;
    border-radius: 8px;
}

.shape_four {
    transform: skew(10deg) !important;
    border-radius: 8px;
}