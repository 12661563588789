#pinAlert {
    margin: 3%;
    border-radius: 10px;
    overflow: hidden;

    div {
        i {
            font-size: 50px;
            display: block;
        }
    }

    input {
        width: 200px;
        align-self: center;
        margin: 20px auto;
    }
}