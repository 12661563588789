@import './constant.scss';

$ICON_SIZE: 100;

.qr-template {
    padding: 15px 30px 25px 30px;
    border: 5px solid #EFEFF7;
    border-radius: 10px;
    margin: 5px;
    text-align: center;

    .qr-badge {
        background: white;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 10px;
        margin-bottom: 5px;
    }
    .qr-container {
        background: $color_two;
        border-radius: 2rem;
        margin-top: 1em;
        padding: 2em 1em;
        .logo {
            box-shadow: 3px 3px 5px 0px rgba(54, 52, 77, 0.15);
            height: 50px;
            width: 50px;
            border-radius: 8px;
        }
    
        .qrInner {
            padding: 10px;
        }
        .bottom-content {
            width: 230px;
            margin-top: 10px;
        }
    }
}