@import './constant.scss';

.login {
  color: $color_two;
  min-height: 100vh;

  .login-bg-image {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
    bottom: 0;
    opacity: 0.1;
    background-size: cover;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat !important;
    background: url(../Shared/Assets/img/login.png) !important;

  }

  .login-container {
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    background: transparent;
    padding: 27px 20px;
    border-radius: 8px;
    z-index: 1;
    margin-top: 10vh;
    margin-bottom: 10vh;

    img {
      width: 90px;
      height: 60px;
    }

    .icon-list {
      padding: 6px 8px;
      border-radius: 8px;
      border: 1px solid $color_two;
      font-size: 12px;

      &:hover {
        background: $color_two;
        color: $color_three;
      }
    }

    button {
      background: transparent;
      border: 1px solid $color_two !important;

      &:hover {
        background: $color_two;
        color: $color_three;
      }

    }
  }

  .forgot-password {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .box_shadow_one {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
  }

  /** Profile wrapper responsive design */
  @media only screen and (max-width: 500px) {
    align-items: baseline;

    .form-card {
      width: 90vw;
      margin-top: 35%;
    }

    .forgot-password {
      font-size: 10px;
    }
  }
}