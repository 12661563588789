  /** Header design types */
  @import '../constant.scss';

  .card_header {
    margin: 35px 15px 15px 15px;

    h1 {
      text-transform: uppercase;
      font-weight: 600;
      background-clip: text;
      -webkit-background-clip: text;
      text-align: left;
    }
  }

  .website_header {
    margin-top: 2em;
    margin-bottom: 1.5em;

    h1 {
      text-transform: uppercase;
      font-weight: 600;
      background-clip: text;
      -webkit-background-clip: text;
      text-align: center;
    }

    @media only screen and (max-width:"700px") {
      margin-top: 1em;
      margin-bottom: 0.5em;
    }
  }
  