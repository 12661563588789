@import '../constant.scss';

.body_wrapper {
    background: $color_one;

    .body_container {
        margin-top: 20px;
    }
}

.body_container {
    margin: 2vw 6vw;

    .w-auto button {
        font-size: 26px;
    }

    .icon-text {
        font-size: 11px;
        text-align: center;
        max-width: 66px;
        margin-top: 3px;
        display: block !important;
    }

    @media only screen and (max-width: 700px) {
        margin: 2vw 3vw;

        .w-auto button {
            font-size: 22px;
        }

        .icon-text {
            font-size: 9px;
            max-width: 55px;
        }

    }
}

.body-card {
    border: 1px solid  $color_one;
    border-radius: 0.5em;
    padding: 3em 4em;
    background: $color_two;
    color: $color_three !important;

    @media only screen and (max-width: 700px) {
        padding: 3vh 2vw;
    }
}

.fs-14 {
    font-size: 14px;
}