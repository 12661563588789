@import './constant.scss';

.calling-card-form {
  font-size: 14px;
  width: 100%;
  overflow: hidden;

  form {
    margin: 69px 2%;

    .card {
      border: 1px solid $color_one;

    }
  }

  .document-view {
    div {
      font-size: 60px;
    }

    span {
      position: relative;
      top: 27px;
      left: 50px;
    }
  }

}

.theme-preview-small {
  height: 72vh;
  overflow-y: auto;
  @media only screen and (max-width: 700px) {
    height: 22vh;
    &.theme-options {
      height: 58vh !important;
    }
  }
}
