@import '../constant.scss';

.tiles {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  div {
    border: 1px solid;
    border-radius: 50%;
    padding: 10px;
    width: 80px;
    height: 80px;

    svg {
      width: 60px;
      height: 60px;
    }

  }

  h4 {
    margin: 10px 0;
  }

}

// Profile image in circle and backgorund colour
.profile-common {
  padding-top: 3px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .profile_image {
    margin: 20px auto;
    margin-top: 100px;
    background-size: cover;
    background-position: center;
    width: 200px !important;
    height: 200px;
  }

  .profile_name {
    .profile-name-container {
      text-align: center;
      padding: 0px 25%;
    }

    h1 {
      margin: 0 0 10px 0;
      font-size: 50px;
      line-height: 56px;
    }

    p {
      font-size: 17px;
      font-family: "Poppins", sans-serif;
      margin: 0px;

      span {
        letter-spacing: 2px;
      }
    }

    button {
      margin-bottom: 30px;
      font-size: 23px;
      padding: 4px 10px;
    }

    h1.profile-name-size-small {
      font-size: 35px;
      line-height: 35px;
    }

    p.profile-name-size-small {
      font-size: 14px;
    }
  }

  // theme class is used to show this card form responsive
  &.theme {
    width: 300px;

    .profile_image {
      width: 170px !important;
      height: 170px;

    }

    .profile_name {
      .profile-name-container {
        padding: 0px 30px;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .profile_image {
      width: 170px !important;
      height: 170px;

    }

    .profile_name {
      .profile-name-container {
        padding: 0px 10%;
      }

      button {
        font-size: 20px;
      }
    }
  }
}

.profile-common-large-image {
  padding-top: 3px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .profile_image {
    margin: 20px auto;
    margin-top: 100px;
    background-size: cover;
    background-position: center;
    width: 50%;
    height: 50vh;
  }

  .profile_name {
    .profile-name-container {
      text-align: center;
      padding: 0px 25%;
    }

    h1 {
      margin: 0 0 10px 0;
      font-size: 50px;
      line-height: 56px;
    }

    p {
      font-size: 17px;
      font-family: "Poppins", sans-serif;
      margin: 0px;

      span {
        letter-spacing: 2px;
      }
    }

    button {
      margin-bottom: 30px;
      font-size: 23px;
      padding: 4px 10px;
    }

    h1.profile-name-size-small {
      font-size: 35px;
      line-height: 35px;
    }

    p.profile-name-size-small {
      font-size: 14px;
    }
  }


  // theme class is used to show this card form responsive
  &.theme {
    width: 300px;

    .profile_image {
      width: calc(100% - 30px);
    }

    .profile_name {
      .profile-name-container {
        padding: 0px 20px;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .profile_image {
      width: calc(100% - 30px);
    }

    .profile_name {
      .profile-name-container {
        padding: 0px 10%;
      }

      button {
        font-size: 20px;
      }
    }
  }
}

.profile-common-custom {

  .profile_image {
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-position: center;
  }

  .profile_name {
    .profile-name-container {
      text-align: center;
      padding: 0px 7%;
    }

    h1 {
      margin: 0 0 10px 0;
      font-size: 55px;
      line-height: 56px;
    }

    p {
      font-size: 17px;
      font-family: "Poppins", sans-serif;
      margin: 0px;

      span {
        letter-spacing: 2px;
      }
    }

    h1.profile-name-size-small {
      font-size: 35px;
      line-height: 35px;
    }

    p.profile-name-size-small {
      font-size: 14px;
    }
  }

  &.theme {
    display: block;
    width: 300px;

    .profile_image {
      width: 300px !important;
      height: 500px;
    }

    .profile_name {
      width: 300px !important;
      padding: 5px 0px;

      .profile-name-container {
        text-align: center;
        padding: 0px 20px;
      }
    }
  }

  /** Profile wrapper responsive design */
  @media only screen and (max-width: 800px) {
    display: block !important;

    .profile_image {
      width: 100vw !important;
      height: 96vh;
    }

    .profile_name {
      width: -webkit-fill-available;
      font-size: 20px;

      .profile-name-container {
        padding: 0px 10%;
      }
    }
  }
}

.profile-one {
  @extend .profile-common;

  .profile_image {
    border: 2px solid $color_two;
  }

  .profile_name {
    color: $color_two;

    p {
      color: $color_two;
    }

    button {
      color: $color_two;
    }
  }
}

//Similat to profile-one and three with black text
.profile-two {
  @extend .profile-common;

  color: $color_three;

  .profile_image {
    border: 2px solid $color_three;
  }

  .profile_name {
    button {
      border-color: $color_three !important;
    }
  }
}

//Similat to profile-one with plain white background
.profile-three {
  @extend .profile-common;
  color: $color_three;

  button {
    border-color: $color_three !important;
  }
}

// Profile image in circle and backgorund colour without image border
.profile-four {
  @extend .profile-common;

  .profile_name {
    color: $color_two;

    p {
      color: $color_two;

    }

    button {
      color: $color_two;
    }
  }
}

.profile-five {
  @extend .profile-common-large-image;

  .profile_image {
    border: 2px solid $color_two;
  }

  .profile_name {
    color: $color_two;

    p {
      color: $color_two;
    }

    button {
      color: $color_two;
    }
  }
}

//Similat to profile-one and three with black text
.profile-six {
  @extend .profile-common-large-image;

  color: $color_three;

  .profile_image {
    border: 2px solid $color_three;
  }

  .profile_name {
    button {
      border-color: $color_three !important;
    }
  }
}

//Similat to profile-one with plain white background
.profile-seven {
  @extend .profile-common-large-image;
  color: $color_three;

  button {
    border-color: $color_three !important;
  }
}

// Profile image in circle and backgorund colour without image border
.profile-eight {
  @extend .profile-common-large-image;

  .profile_name {
    color: $color_two;

    p {
      color: $color_two;

    }

    button {
      color: $color_two;
    }
  }
}

// Half image and half name & merged on response
.profile-nine {
  @extend .profile-common-custom;
  display: flex;

  .profile_image {
    width: 50vw;
    height: 100vh;
  }

  .profile_name {
    width: 50vw;
    color: $color_two;
    padding: 23px 0px;

    button {
      color: $color_two;
    }
  }

  &.theme {

    .profile_name {
      background: #00000080;
    }

    .profile_name {
      position: absolute;
      bottom: 40px !important;
    }
  }

  /** Profile wrapper responsive design */
  @media only screen and (max-width: 800px) {

    .profile_name {
      width: -webkit-fill-available;
      background: #00000080;
      position: absolute;
      bottom: 10vh;
    }

  }
}

.profile-ten {
  @extend .profile-common-custom;
  display: flex;

  .profile_image {
    width: 50vw;
    height: 100vh;
  }

  .profile_name {
    width: 50vw;
    color: $color_three;
    padding: 23px 0px;

    button {
      color: $color_three;
      border-color: $color_three !important;
    }
  }

  &.theme {

    .profile_name {
      background: #00000080;
    }

    .profile_name {
      position: absolute;
      bottom: 40px !important;
    }
  }

  /** Profile wrapper responsive design */
  @media only screen and (max-width: 800px) {

    .profile_name {
      width: -webkit-fill-available;
      background: #00000080;
      position: absolute;
      bottom: 10vh;
    }

  }
}

// Half image and half name & name listed below image while responsive

.profile-eleven {
  @extend .profile-common-custom;

  display: flex;

  .profile_image {
    width: 51vw;
    height: 100vh;
  }

  .profile_name {
    width: 50vw;
    color: $color_two;
    padding: 23px 0px !important;

    button {
      color: $color_two;
    }
  }

  /** Profile wrapper responsive design */
  @media only screen and (max-width: 800px) {
    border-radius: 20px;

    .profile_name {
      width: 100vw;
    }
  }
}

.profile-twelve {
  @extend .profile-common-custom;

  display: flex;

  .profile_image {
    width: 51vw;
    height: 100vh;
  }

  .profile_name {
    width: 50vw;
    color: $color_three;
    padding: 23px 0px !important;

    button {
      color: $color_three;
      border-color: $color_three !important;
    }
  }

  /** Profile wrapper responsive design */
  @media only screen and (max-width: 800px) {
    border-radius: 20px;

    .profile_name {
      width: 100vw;
    }
  }
}


// Profile image full screen with name as layer

.profile-thirteen {
  @extend .profile-common-custom;

  .profile_image {
    width: 100vw;
    height: 100vh;
  }

  .profile_name {
    width: 100%;
    position: absolute;
    bottom: 0vh;
    color: $color_two;

    .waves {
      margin-top: 10vh;
    }


    button {
      color: $color_two !important;
    }
  }

  // theme class is used to show this card form responsive
  &.theme {

    .profile_name {
      bottom: 0vh;

      .waves {
        margin-top: 5vh;
      }
    }
  }

}

.profile-fourteen {
  @extend .profile-common-custom;

  .profile_image {
    width: 100vw;
    height: 100vh;
  }

  .profile_name {
    width: 100%;
    position: absolute;
    bottom: 0vh;
    color: $color_three;

    .waves {
      margin-top: 10vh;
    }


    button {
      color: $color_three !important;
      border-color: $color_three !important;
    }
  }

  // theme class is used to show this card form responsive
  &.theme {

    .profile_name {
      bottom: 0vh;

      .waves {
        margin-top: 5vh;
      }
    }
  }

}