@import '../constant.scss';

.profile-link-card {
  width: 100vw;
  height: 100vh;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 14px;

  .top-icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  svg {
    width: 30px;
    height: 40px;
  }

  .link-list {
    text-align: center;
    width: 50vw;
    margin: 0.5rem auto;
  }

  .profile_name {
    padding: 3em 25%;

    h1 {
      margin: 0 0 10px 0;
      font-size: 50px;
      line-height: 56px;
    }

    p {
      font-size: 16px;
      margin: 0px;

      span {
        letter-spacing: 2px;
      }
    }

    button {
      font-size: 23px;
      padding: 4px 10px;
      min-height: 50px;
      min-width: 50px;

      &:disabled {
        opacity: 1;
      }
    }

    h1.profile-name-size-small {
      font-size: 35px;
      line-height: 35px;
    }

    p.profile-name-size-small {
      font-size: 14px;
    }

    h5 {
      margin-bottom: 10px;
    }
  }

  .icon-text {
    font-size: 11px;
    text-align: center;
    max-width: 66px;
    margin-top: 3px;
    display: block !important;
}
.card-logo {
  margin-top: 70px;
}

  // theme class is used to show this card form responsive
  &.theme {
    width: 400px !important;
    height: 60vh !important;
    font-size: 12px !important;

    .link-list {
      width: 330px;
    }

    .profile_name {
      padding: 3em;

      button {
        font-size: 20px;
        padding: 8px;
        min-height: 47px;
        min-width: 47px;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

  }

  @media only screen and (max-width: 700px) {

    .link-list {
      width: 85vw;
    }

    .profile_name {
      padding: 1em 3em 3em;

      button {
        font-size: 16px;
        padding: 8px;
        min-height: 47px;
        min-width: 47px;

        svg {
          width: 30px;
          height: 30px;
        }

        i {
          font-size: 20px;
        }
      }

      h5 {
        font-size: 16px;
      }

      p {
        font-size: 15px !important;
      }
    }

    &.theme {
      max-width: 330px;
      width: 100% !important;
      font-size: 12px !important;

      .link-list {
        width: 100% !important;
      }
    }
  }
}

.profile-link-card-three {
  color: $color_two;

  button {
    color: $color_two;

    &:active,
    &:focus,
    &:hover {
      color: $color_three;
      background: $color_two !important;
    }
  }
}

.profile-link-card-four {
  color: $color_three;

  button {
    color: $color_three;
    border-color: $color_three !important;


    &:active,
    &:focus,
    &:hover {
      color: $color_two;
      background: $color_three !important;
    }
  }
}

.profile-link-card-five {
  color: $color_two;

  button {
    background: $color_two !important;
    color: $color_three !important;

    &:active,
    &:focus,
    &:hover {
      color: $color_two;
      background: transparent !important;
      border: 1px solid $color_two !important;
    }
  }
}

.profile-link-card-six {
  color: $color_three;

  button {
    background: $color_three !important;
    color: $color_two !important;
    border: none !important;

    &:active,
    &:focus,
    &:hover {
      color: $color_three !important;
      background: transparent !important;
      border: 1px solid $color_three !important;
    }
  }

}

.base_static_bg {
  button {
    color: $color_two;
    border: none !important;

    &:disabled {
      color: $color_two !important;
    }

    &:active,
    &:focus,
    &:hover {
      color: $color_three;
      background: transparent !important;
      border: 1px solid $color_three !important;
    }
  }
}

.profile-link-card-seven {
  background: $color_two !important;
  @extend .base_static_bg;
}

.profile-link-card-eight {
  color: $color_two;
  background: $color_fourteen !important;
  @extend .base_static_bg;

  button {

    &:active,
    &:focus,
    &:hover {
      color: $color_two;
      background: transparent !important;
      border: 1px solid $color_two !important;
    }
  }
}

.profile-link-card-nine {
  background: #bdbdbd !important;
  @extend .base_static_bg;
}

.profile-link-card-ten {
  background: #f8d8d8 !important;
  @extend .base_static_bg;
}

.profile-link-card-eleven {
  background: #ffffcf !important;
  @extend .base_static_bg;
}

.profile-link-card-twelve {
  background: #e5e8fa !important;
  @extend .base_static_bg;
}

.profile-link-card-thirteen {
  background: #e5fae6 !important;
  @extend .base_static_bg;
}