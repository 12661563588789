@import './constant.scss';

.share_container {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 50px;
  height: 50px;

  button {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 1px solid $color_two !important;

    i {
      font-size: 16px;
    }

    &:hover {
      border: 1px solid $color_three !important;
      background: white;
      color: $color_three;
    }
  }

  @media only screen and (max-width: 700px) {
    right: 20px;
    bottom: 30px;
  }
}

.connection_container {
  position: fixed;
  bottom: 40px;
  right: 90px;

  button {
    border-radius: 8px;
    background: transparent;
    padding: 5px 20px;
    margin: 5px;
    border: 1px solid $color_one !important;

    &:hover {
      border-color: $color_three !important;
      background: white;
      color: $color_three;
    }
  }

  @media only screen and (max-width: 700px) {
    right: 75px;
    bottom: 30px;
  }
}
