@import './constant.scss';

.empty-state {
    width: auto;
    margin: 20px 5px !important;
    border: 1px solid $color_one;
    border-radius: 4px;
  
    &__content {
      padding: 45px;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
  
      .empty-state__icon {
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        border-radius: 200px;
        justify-content: center;
        box-shadow: 0px 2px 1px $color_twelve;
  
        img {
          width: 170px;
        }
      }
  
      .empty-state__message {
        font-size: 16px;
        font-weight: 500;
        margin-top: 0.85rem;
        margin-bottom: 5px;
      }
  
      .empty-state__help {
        color: $color_eleven;
        font-size: 14px;
        width: fit-content;
      }

    }
    @media only screen and (max-width: 700px) {
        .empty-state__icon {
            width: 150px;
            height: 150px;
            border-radius: 200px;
            img {
              width: 90px;
            }
          }
      }
  }
  