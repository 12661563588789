@import './constant.scss';

.invalid-custom-input {
    width: 100%;
    margin: 4px 0px 0px 10px;
    font-size: 10px;
    color: $color_five;

    @media only screen and (max-width: 700px) {
        font-size: 10px;
    }
}

.customInputWrapper {
    .input-layer {
        height: 42px;
    }

    .textarea {
        height: 124px !important;

        textarea {
            resize: none;
        }

        label {
            top: -8.29rem;

        }
    }

    input,
    textarea,
    select {
        color: $color_nine;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-family: Roboto, sans-serif;
        padding: 10px 16px;
        border-radius: 7px;
        margin-top: 10px;
        box-shadow: none !important;
        min-width: 200px;

        &::placeholder {
            color: $color_ten;
        }

        &.is-invalid::placeholder {
            color: $color_five;
        }

        &:focus {
            box-shadow: none;
            border-color: $color_nine;

            &::placeholder {
                color: transparent;
            }
        }
    }

    label {
        visibility: hidden;
        color: $color_ten;
        font-size: 11px;
        position: relative;
        top: -3.375rem;
        left: 10px;
        background: $color_two;
        padding: 0px 5px;
        border-radius: 4px;
    }

    input:not(:placeholder-shown)+.input-label,
    input:focus+.input-label,
    textarea:not(:placeholder-shown)+.input-label,
    textarea:focus+.input-label,
    select:not(:placeholder-shown)+.input-label,
    select:focus+.input-label {
        visibility: visible;
    }

    input:focus+.input-label,
    textarea:focus+.input-label,
    select:focus+.input-label {
        visibility: visible;
        color: $color_nine;
    }

    input.is-invalid+.input-label,
    textarea.is-invalid+.input-label,
    select.is-invalid+.input-label {
        color: $color_five;
    }

    @media only screen and (max-width: 700px) {
        width: 100% !important;
    }
}

.search {

    input {
        color: $color_nine;
        outline: none !important;

        &::placeholder {
            color: $color_ten;
            font-size: 12px;
        }

        &:focus,
        &:active,
        &:focus-visible {
            border-color: #dee2e6;
            outline: none !important;
            box-shadow: none !important;

            &::placeholder {
                color: transparent;
            }
        }
    }

    @media only screen and (max-width: 700px) {
        input {
            width: 150px !important;
        }
    }

    @media only screen and (max-width: 350px) {
        input {
            width: 100px !important;
        }
    }

}
