@import './constant.scss';

.progress-circular-bar {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: $color_fifteen;
  overflow: hidden;

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    background: $color_two;
    border-radius: 50%;
    font-size: 1em;
    font-weight: 300;
    color: $color_three;
    padding: 10px;
    text-align: center;
    word-break: break-word;
    text-transform: capitalize;
  }

  @media only screen and (max-width: 600px)  {
    width: 90px;
    height: 90px;
    .inner {
      width: 70px;
      height: 70px;
      font-size: .675em;
    }
  }
}