@import '../constant.scss';

.org_name_one {
    width: 100%;
    text-align: center;
    padding: 20px 0px 20px 20px;
    z-index: 1;
    position: absolute;

    img {
        height: 40px;
    }
}

.org_name_two {
    width: 100%;
    text-align: left;
    padding: 20px 0px 20px 20px;
    z-index: 1;
    position: absolute;

    img {
        height: 40px;
    }
}

.org_name_three {
    width: 100%;
    text-align: right;
    padding: 20px 20px 30px 20px;
    z-index: 1;
    position: absolute;

    img {
        height: 40px;
    }
}

.org_name_four {
    width: 100%;
    text-align: center;
    z-index: 1;
    position: absolute;
    background: $color_two;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    top: 0px;

    img {
        height: 60px;
    }
}

.org_name_five {
    width: 100%;
    text-align: center;
    z-index: 1;
    position: absolute;
    background: $color_two;
    border-radius: 8px;
    top: 10px;

    img {
        height: 60px;
    }
}

.org_name_six {
    width: auto;
    z-index: 1;
    position: absolute;
    background: $color_two;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 0px 6px;
    top: 10px;

    img {
        height: 40px;
    }
}

.org_name_seven {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: end;
    z-index: 1;
    position: absolute;
    width: 80px;
    background: $color_two;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    right: 0px;
    padding: 0px 6px;
    top: 10px;

    img {
        height: 40px;
    }
}