@import './constant.scss';

body {
  // font-family: "Quicksand", Arial, sans-serif !important;
  // font-family: Garamond, serif !important;
  // font-family: "Poppins", sans-serif !important;
  // font-family: "Raleway", sans-serif;
  // font-family: 'Muli', sans-serif !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 400;
  color: $color_three !important;
  letter-spacing: .3px;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
}

.moile-view-contents {
  display: none !important;

  @media only screen and (max-width: 700px) {
    display: inline !important;
  }
}

.icon-button-wrapper {

  i {
    border-radius: 0px;
    color: $color_seven;
    padding: 6px 8px;
    border-radius: 8px;
    border: 1px solid $color_eight;
    font-size: 14px;
    margin-right: 2px;

  }
}

.card-border {
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  padding-bottom: 2px;
}

.icon-button-alligment {
  padding: 6px 8px;
  font-size: 12px !important;
}

.icon-button-list-alligment {
  padding: 2px 6px;

  button {
    padding: 6px 0px;
  }
}

button:active,
button,
button:focus {
  border: none !important;
}

.specalNotifywrapper {
  text-align: center;
  padding: 0px 0px 5% 0px !important;
  border-radius: 20px;

  h5 {
    padding: 28px 5px;
    color: $color_two;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-family: "Anta", sans-serif;
  }

  img,
  iframe {
    width: 70%;
  }

  p {
    padding: 3%;
  }
}

.modal-footer {

  .btn-primary,
  .btn-secondary {
    font-size: 14px !important;
  }
}

.floating-delete-icon {
  width: 30px !important;
  height: 30px;
  position: absolute;
  top: 0px;
  right: -1px;
  padding: 0px !important;

  i {
    color: $color_two;
    font-size: 12px;
  }
}

.view-data-table {
  max-width: 100px;

  @media only screen and (max-width: 700px) {
    min-width: 55px;
    font-size: 12px;
  }

}

.margin-top-loader {
  margin-top: 40vh !important;
}

.margin-top-loader-small {
  margin-top: 30vh !important;
}

.app-loader {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 100px;
  padding-top: 40vh;
}

.important,
.error {
  color: $color_five;
}

.border_one {
  border: 2px solid $color_two !important;
}

.border_two {
  border: 1px solid $color_eight;
}

.border_three {
  border-color: $color_two !important;
}

.border_four {
  border-color: $color_three !important;
}

.bodyWrapper {
  margin-top: 90px !important;
  min-height: 80vh;
  padding: 3vh 3vw;

  @media only screen and (max-width:"700px") {
    margin-top: 75px !important;
    padding: 3vh 6vw;
  }
}

.upload-file-wrapper {
  border: 1px dotted $color_eight;
  background: $color_one;
  width: 160px;
  height: 160px;
  padding: 0.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
  text-align: center;
}

button {
  outline: none;
}

form {

  .col-md-6,
  .col-md-4 {
    margin-bottom: 10px;
  }
}

.small_icon {
  svg {
    width: 22px !important;
    height: 30px;
  }
}

.tiny_icon {
  svg {
    width: 18px !important;
    height: 30px;
  }
}

.btn,
.icon-design {

  svg {
    width: 30px;
    height: 40px;
  }

  @media only screen and (max-width: 700px) {
    svg {
      width: 22px;
      height: 35px;
    }
  }

}

.themes-icon-design {
  width: fit-content !important;

  svg {
    width: 10px;
    height: 10px;
  }
}

.circled-icon {
  margin: 0 auto;
  border: 2px solid white;
  padding: 12px 15px;
  border-radius: 50%;
  margin-bottom: 4px;
  margin-top: 5px;
}

.profile-dropdown .dropdown-item {
  width: 200px !important;
  text-wrap: wrap !important;
  word-wrap: break-word;
}

.navbar.navbar-expand {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.box_shadow {
  box-shadow: 3px 3px 5px 0px $color_twelve;
}

// Font sizes

.font-size-one {
  font-size: 14px !important;
}

.font-size-two {
  font-size: 12px !important;
}

.font-size-three {
  font-size: 8px !important;
}

.font-size-four {
  font-size: 16px !important;
}

.font-color-one {
  color: $color_fifteen !important;
}

.bg_website_one {
  background: radial-gradient(circle at 3% 4.8%, #00ceab 20%, #22c8e5 86.6%);
}

.width-icon {
  width: 20px;
}

.terms {
  border: 1px solid grey;
  height: 200px;
  overflow-y: scroll;
  padding: 15px;
  margin: 10px 0px;
  border-radius: 8px;
  font-size: 10px;
  line-height: 1.6;

  h6 {
    font-size: 10px;
    font-weight: 600;
    margin-top: 10px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
button {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

a  {
  cursor: pointer;
}
