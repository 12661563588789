@import './constant.scss';

.card-form-tab.nav-tabs {
    border: none !important;
    background: $color_one;
    border-radius: 12px;
    padding: 0px 10px;

    .nav-item {
        background: $color_one;
        padding: 5px 5px 5px 6px;

        .nav-link {
            border-radius: 0px;
            color: $color_three;
            padding: 4px 10px;
            border-radius: 8px;
            border: 1px solid $color_two ;

            label {
                svg {
                    width: 20px;
                    height: 25px;
                }
            }

        }

        .nav-link.active {
            background: $color_three;
            color: $color_one;
            padding: 4px 15px;
        }
    }

    @media only screen and (max-width: 700px) {
        .nav-item {
            padding: 5px 0px 5px 2px;

            .nav-link {
                padding: 4px 6px;

                .title-text {
                    display: none;
                }

                label {
                    svg {
                        width: 18px;
                        height: 20px;
                    }
                }

            }
        }
    }
}

.tab-content {
    padding: 0px !important;

    .tab-pane {
        padding: 0px !important;
        min-height: 40vh;
    }
}