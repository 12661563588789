.animation_one {
  animation: wiggle 3s linear infinite;
}
.animation_two {
  animation: float 3s ease-in-out infinite;
}

.animation_three {
  animation-name: grow;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.animation_four {
  animation: move 3s ease-in infinite;
}

@keyframes move {
  0% {
    transform: translate(0px, 0px);
  }
  40% {
    transform: translate(-12px, -12px);
  }
  50% {
    transform: translate(0px, 0px);
  }
  60% {
    transform: translate(12px, 12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes float {
	0% {
		transform: translatey(-10px);
	}
  50% {
		transform: translatey(10px);
	}
	100% {
		transform: translatey(-10px);
	}
}

@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-10deg);
  }
  20% {
    transform: rotateZ(8deg);
  }
  25% {
    transform: rotateZ(-8deg);
  }
  30% {
    transform: rotateZ(4deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

@keyframes grow {
  0% {
    transform: scaleX(1);
  }
  75% {
    transform: scaleX(1.3);
  }
}
