@import '../constant.scss';

@import url(https://fonts.googleapis.com/css2?family=Anta&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anta&family=Micro+5&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Protest+Revolution&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Protest+Revolution&family=Protest+Riot&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Protest+Revolution&family=Protest+Riot&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Protest+Revolution&family=Protest+Riot&family=Teko:wght@300..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Protest+Guerrilla&family=Protest+Revolution&family=Protest+Riot&family=Teko:wght@300..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Protest+Guerrilla&family=Protest+Revolution&family=Protest+Riot&family=Teko:wght@300..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Exo+2:ital,wght@0,100..900;1,100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Protest+Guerrilla&family=Protest+Revolution&family=Protest+Riot&family=Teko:wght@300..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Exo+2:ital,wght@0,100..900;1,100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Protest+Guerrilla&family=Protest+Revolution&family=Protest+Riot&family=Rubik+Glitch+Pop&family=Teko:wght@300..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barriecito&family=Caveat:wght@400..700&family=Exo+2:ital,wght@0,100..900;1,100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Protest+Guerrilla&family=Protest+Revolution&family=Protest+Riot&family=Rubik+Glitch+Pop&family=Teko:wght@300..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barriecito&family=Caveat:wght@400..700&family=Exo+2:ital,wght@0,100..900;1,100..900&family=Honk&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Protest+Guerrilla&family=Protest+Revolution&family=Protest+Riot&family=Rubik+Glitch+Pop&family=Teko:wght@300..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barriecito&family=Caveat:wght@400..700&family=Exo+2:ital,wght@0,100..900;1,100..900&family=Honk&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Protest+Guerrilla&family=Protest+Revolution&family=Protest+Riot&family=Rubik+Glitch+Pop&family=Sixtyfour&family=Teko:wght@300..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rhodium+Libre&display=swap);

//Profile name font family type
.profile-font-family-two {
    font-family: cursive;
}

.profile-font-family-one {
    font-family: 'Courier New', Courier, monospace;
}

.profile-font-family-three {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.profile-font-family-four {
    font-family: "Anta", sans-serif;
}

.profile-font-family-five {
    font-family: "Micro 5", sans-serif;
}

.profile-font-family-six {
    font-family: "Ubuntu", sans-serif;
}

.profile-font-family-seven {
    font-family: "Protest Revolution", sans-serif;
}

.profile-font-family-seven {
    font-family: "Protest Riot", sans-serif;
}

.profile-font-family-eight {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
}

.profile-font-family-nine {
    font-family: "Teko", sans-serif;
    font-optical-sizing: auto;
}

.profile-font-family-ten {
    font-family: "Protest Guerrilla", sans-serif;
    font-optical-sizing: auto;
}

.profile-font-family-eleven {
    font-family: "Exo 2", sans-serif;
    font-optical-sizing: auto;
}

.profile-font-family-twelve {
    font-family: "Caveat", cursive;
    font-optical-sizing: auto;
}

.profile-font-family-thirteen {
    font-family: "Rubik Glitch Pop", system-ui;
}

.profile-font-family-fourteen {
    font-family: "Barriecito", system-ui;
}

.profile-font-family-fifteen {
    font-family: "Honk", system-ui;
    font-optical-sizing: auto;
    font-variation-settings:
        "MORF" 15,
        "SHLN" 50;
}

.profile-font-family-sixteen {
    font-family: "Sixtyfour", sans-serif;
    font-optical-sizing: auto;
    font-variation-settings:
        "BLED" 0,
        "SCAN" 0;
}

.profile-font-family-seventeen {
    font-family: "Poppins", sans-serif;
}

//Profile buttons styles
.profile-button-one {
    border: 1px solid !important;
    color:  $color_two;
    margin: 0px 5px 5px 5px;
}

.profile-button-two {
    color: $color_two;
    border: 0px solid !important;
    margin: 0px 5px 5px 5px;
}

.profile-button-three {
    border: 1px solid !important;
    color: $color_two;
}

.profile-button-four {
    color: $color_two;
    border: 0px solid !important;
}

.profile-button-five {
    border-bottom: 1px solid !important;
    color:  $color_two;
    margin: 0px 5px 5px 5px;
}

.profile-button-six {
    border-top: 1px solid !important;
    color:  $color_two;
    margin: 0px 5px 5px 5px;
}

.profile-font-weight-one {
    font-weight: 700;
}