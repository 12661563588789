/** Background colors */
.background_one {
    background: #3B3B9E !important;
}

.background_two {
    background: rgba(4, 71, 52, 1) !important;
}

.background_three {
    background: #88AB8E !important;
}

.background_four {
    background: #EE7214 !important;
}

.background_five {
    background: #FFB534 !important;
}

.background_six {
    background: #FF6D60 !important;
}

.background_seven {
    background: #F35588 !important;
}

.background_eight {
    background: #0F1123 !important;
}

.background_nine {
    background: #fe4a49 !important;
}

.background_ten {
    background: #2ab7ca !important;
}

.background_eleven {
    background: #fed766 !important;
}

.background_twelve {
    background: #fe8a71 !important;
}

.background_thirteen {
    background: #0e9aa7 !important;
}

.background_fourteen {
    background: #009688 !important;
}

.background_fifteen {
    background: #54b2a9 !important;
}

.background_sixteen {
    background: #dcedc1 !important;
}

.background_seventeen {
    background: #536878 !important;
}

.background_eighteen {
    background: #6e7f80 !important;
}

.background_ninteen {
    background: #854442 !important;
}

.background_twenty {
    background: #3b5998 !important;
}

.background_twentyone {
    background: #404040 !important;
}

.background_grad_one {
    background: linear-gradient(90deg, rgba(4, 71, 52, 1) 0%, rgba(9, 121, 52, 1) 33%, rgba(220, 255, 0, 1) 100%) !important;
}

.background_grad_two {
    background: linear-gradient(109.6deg, rgba(156, 252, 248, 1) 11.2%, rgba(110, 123, 251, 1) 91.1%) !important;
}

.background_grad_three {
    background: linear-gradient(to right, #ff9966, #ff5e62) !important;
}

.background_grad_four {
    background: linear-gradient(to bottom right, #5761B2, #1FC5A8) !important;
}

.background_grad_five {
    background-image: linear-gradient(to bottom right, #FF3E9D, #FFB534) !important;
}

.background_grad_six {
    background-image: linear-gradient(to bottom right, #FFDB01, #EE7214) !important;
}

.background_grad_seven {
    background-image: linear-gradient(to bottom right, #FF512F, #DD2476) !important;
}

.background_grad_eight {
    background-image: linear-gradient(to bottom right, #FF61D2, #FE9090) !important;
}

.background_grad_nine {
    background-image: linear-gradient(to bottom right, #305170, #6DFC6B) !important;
}

.background_grad_ten {
    background-image: linear-gradient(to bottom right, #849B5C, #BFFFC7) !important;
}

.background_grad_eleven {
    background-image: linear-gradient(to bottom right, #A96f44, #f2ecb6) !important;
}

.background_grad_twelve {
    background-image: linear-gradient(to bottom right, #9fa5d5, #e8f5c8) !important;
}

.background_grad_thirteen {
    background-image: linear-gradient(120deg, #155799, #159957) !important;
}

.background_striped_one {
    background: linear-gradient(45deg, #C2E2F5 30%, #4B7BF5 30%, #4B7BF5 60%, #0000FF 60%, #0000FF 90%);
}

.background_striped_two {
    background: linear-gradient(45deg, #000000 30%, #666666 30%, #666666 60%, #CCCCCC 60%, #CCCCCC 90%);
}

.background_striped_three {
    background: linear-gradient(45deg, #ECF87F 30%, #81B622 30%, #81B622 60%, #3D550C 60%, #3D550C 90%);
}

.background_striped_four {
    background: linear-gradient(45deg, #B1D4E0 30%, #145DA0 30%, #145DA0 60%, #0C2D48 60%, #0C2D48 90%);
}

.background_striped_five {
    background: linear-gradient(45deg, #FB8DA0 30%, #FB6B90 30%, #FB6B90 60%, #FB4570 60%, #FB4570 90%);
}

.background_striped_six {
    background: linear-gradient(45deg, #FBE39D 30%, #F9D876 30%, #F9D876 60%, #FEC84D 60%, #FEC84D 90%);
}

.background_striped_seven {
    background: linear-gradient(45deg, #b7dfef 30%, #71D5E4 30%, #71D5E4 60%, #00B6BC 60%, #00B6BC 90%);
}

.background_striped_eight {
    background: linear-gradient(45deg, #F3ECDA 30%, #C4AC95 30%, #C4AC95 60%, #94553D 60%, #94553D 90%);
}

.background_radial_one {
    background: radial-gradient(#3B3B9E, #3B3B9E85 1%, #3B3B9E);
}

.background_radial_two {
    background: radial-gradient(rgba(4, 71, 52, 1), #dddddd 1%, rgba(4, 71, 52, 1));
}

.background_radial_three {
    background: radial-gradient(#88AB8E, #88AB8E85 1%, #88AB8E);
}

.background_radial_four {
    background: radial-gradient(#EE7214, #EE721485 1%, #EE7214);
}

.background_radial_five {
    background: radial-gradient(#FFB534, #FFB53485 1%, #FFB534);
}

.background_radial_six {
    background: radial-gradient(#FF6D60, #FF6D6085 1%, #FF6D60);
}

.background_radial_seven {
    background: radial-gradient(#F35588, #F3558885 1%, #F35588);
}

.background_radial_eight {
    background: radial-gradient(#0F1123, #0F112385 1%, #0F1123);
}

.background_radial_nine {
    background: radial-gradient(#fe4a49, #fe4a4985 1%, #fe4a49);
}

.background_radial_ten {
    background: radial-gradient(#2ab7ca, #2ab7ca85 1%, #2ab7ca);
}

.background_radial_eleven {
    background: radial-gradient(#fed766, #fed76685 1%, #fed766);
}

.background_radial_twelve {
    background: radial-gradient(#fe8a71, #fe8a7185 1%, #fe8a71);
}

.background_radial_thirteen {
    background: radial-gradient(#0e9aa7, #0e9aa785 1%, #0e9aa7);
}

.background_radial_fourteen {
    background: radial-gradient(#009688, #00968885 1%, #009688);
}

.background_radial_fifteen {
    background: radial-gradient(#54b2a9, #54b2a985 1%, #54b2a9);
}

.background_radial_sixteen {
    background: radial-gradient(#dcedc1, #dcedc185 1%, #dcedc1);
}

.background_radial_seventeen {
    background: radial-gradient(#536878, #53687885 1%, #536878);
}

.background_radial_eighteen {
    background: radial-gradient(#6e7f80, #6e7f8085 1%, #6e7f80);
}

.background_radial_ninteen {
    background: radial-gradient(#854442, #85444285 1%, #854442);
}

.background_radial_twenty {
    background: radial-gradient(#3b5998, #3b599885 1%, #3b5998);
}

.background_radial_twentyone{
    background: radial-gradient(#404040, #40404085 1%, #404040);
}


.background_repeat_linear_one {
    background: repeating-linear-gradient(45deg, #3B3B9E 5%, #3B3B9E85 10%);
}

.background_repeat_linear_two {
    background: repeating-linear-gradient(45deg, rgba(4, 71, 52, 1) 5%, #ddd 10%);
}

.background_repeat_linear_three {
    background: repeating-linear-gradient(45deg, #88AB8E 5%, #88AB8E85 10%);
}

.background_repeat_linear_four {
    background: repeating-linear-gradient(45deg, #EE7214 5%, #EE721485 10%);
}

.background_repeat_linear_five {
    background: repeating-linear-gradient(45deg, #FFB534 5%, #FFB53485 10%);
}

.background_repeat_linear_six {
    background: repeating-linear-gradient(45deg, #FF6D60 5%, #FF6D6085 10%);
}

.background_repeat_linear_seven {
    background: repeating-linear-gradient(45deg, #F35588 5%, #F3558885 10%);
}

.background_repeat_linear_eight {
    background: repeating-linear-gradient(45deg, #0F1123 5%, #0F112385 10%);
}

.background_repeat_linear_nine {
    background: repeating-linear-gradient(45deg, #fe4a49 5%, #fe4a4985 10%);
}

.background_repeat_linear_ten {
    background: repeating-linear-gradient(45deg, #2ab7ca 5%, #2ab7ca85 10%);
}

.background_repeat_linear_eleven {
    background: repeating-linear-gradient(45deg, #fed766 5%, #fed76685 10%);
}

.background_repeat_linear_twelve {
    background: repeating-linear-gradient(45deg, #fe8a71 5%, #fe8a7185 10%);
}

.background_repeat_linear_thirteen {
    background: repeating-linear-gradient(45deg, #0e9aa7 5%, #0e9aa785 10%);
}

.background_repeat_linear_fourteen {
    background: repeating-linear-gradient(45deg, #009688 5%, #00968885 10%);
}

.background_repeat_linear_fifteen {
    background: repeating-linear-gradient(45deg, #54b2a9 5%, #54b2a985 10%);
}

.background_repeat_linear_sixteen {
    background: repeating-linear-gradient(45deg, #dcedc1 5%, #dcedc185 10%);
}

.background_repeat_linear_seventeen {
    background: repeating-linear-gradient(45deg, #536878 5%, #53687885 10%);
}

.background_repeat_linear_eighteen {
    background: repeating-linear-gradient(45deg, #6e7f80 5%, #6e7f8085 10%);
}

.background_repeat_linear_ninteen {
    background: repeating-linear-gradient(45deg, #854442 5%, #85444285 10%);
}

.background_repeat_linear_twenty {
    background: repeating-linear-gradient(45deg, #3b5998 5%, #3b599885 10%);
}

.background_repeat_linear_twentyone {
    background: repeating-linear-gradient(45deg, #404040 5%, #40404085 10%);
}

.background_repeat_radial_one {
    background: -webkit-repeating-radial-gradient(center, circle, #3B3B9E 5%, #3B3B9E85 10%);
}

.background_repeat_radial_two {
    background: -webkit-repeating-radial-gradient(center, circle, rgba(4, 71, 52, 1) 5%, #3B3B9E85 10%);
}

.background_repeat_radial_three {
    background: -webkit-repeating-radial-gradient(center, circle, #88AB8E 5%, #88AB8E85 10%);
}

.background_repeat_radial_four {
    background: -webkit-repeating-radial-gradient(center, circle, #EE7214 5%, #EE721485 10%);
}

.background_repeat_radial_five {
    background: -webkit-repeating-radial-gradient(center, circle, #FFB534 5%, #FFB53485 10%);
}

.background_repeat_radial_six {
    background: -webkit-repeating-radial-gradient(center, circle, #FF6D60 5%, #FF6D6085 10%);
}

.background_repeat_radial_seven {
    background: -webkit-repeating-radial-gradient(center, circle, #F35588 5%, #F3558885 10%);
}

.background_repeat_radial_eight {
    background: -webkit-repeating-radial-gradient(center, circle, #0F1123 5%, #0F112385 10%);
}

.background_repeat_radial_nine {
    background: -webkit-repeating-radial-gradient(center, circle, #fe4a49 5%, #fe4a4985 10%);
}

.background_repeat_radial_ten {
    background: -webkit-repeating-radial-gradient(center, circle, #2ab7ca 5%, #2ab7ca85 10%);
}

.background_repeat_radial_eleven {
    background: -webkit-repeating-radial-gradient(center, circle, #fed766 5%, #fed76685 10%);
}

.background_repeat_radial_twelve {
    background: -webkit-repeating-radial-gradient(center, circle, #fe8a71 5%, #fe8a7185 10%);
}

.background_repeat_radial_thirteen {
    background: -webkit-repeating-radial-gradient(center, circle, #0e9aa7 5%, #0e9aa785 10%);
}

.background_repeat_radial_fourteen {
    background: -webkit-repeating-radial-gradient(center, circle, #009688 5%, #00968885 10%);
}

.background_repeat_radial_fifteen {
    background: -webkit-repeating-radial-gradient(center, circle, #54b2a9 5%, #54b2a985 10%);
}

.background_repeat_radial_sixteen {
    background: -webkit-repeating-radial-gradient(center, circle, #dcedc1 5%, #dcedc185 10%);
}

.background_repeat_radial_seventeen {
    background: -webkit-repeating-radial-gradient(center, circle, #536878 5%, #53687885 10%);
}

.background_repeat_radial_eighteen {
    background: -webkit-repeating-radial-gradient(center, circle, #6e7f80 5%, #6e7f8085 10%);
}

.background_repeat_radial_ninteen {
    background: -webkit-repeating-radial-gradient(center, circle, #854442 5%, #85444285 10%);
}

.background_repeat_radial_twenty {
    background: -webkit-repeating-radial-gradient(center, circle, #3b5998 5%, #3b599885 10%);
}

.background_repeat_radial_twentyone {
    background: -webkit-repeating-radial-gradient(center, circle, #404040 5%, #40404085 10%);
}

.background_multi_gradient_linear_one {
    background: repeating-linear-gradient(45deg, #3B3B9E 5%, #3B3B9E85 10%), repeating-linear-gradient(-45deg, #3B3B9E 5%, #3B3B9E85 10%)
}

.background_multi_gradient_linear_two {
    background: repeating-linear-gradient(45deg, rgba(4, 71, 52, 1) 5%, rgba(4, 71, 52, 0.7) 10%), repeating-linear-gradient(-45deg, rgba(4, 71, 52, 1) 5%, rgba(4, 71, 52, 0.7) 10%)
}

.background_multi_gradient_linear_three {
    background: repeating-linear-gradient(45deg, #88AB8E 5%, #88AB8E85 10%), repeating-linear-gradient(-45deg, #88AB8E 5%, #88AB8E85 10%)
}

.background_multi_gradient_linear_four {
    background: repeating-linear-gradient(45deg, #EE7214 5%, #EE721485 10%), repeating-linear-gradient(-45deg, #EE7214 5%, #EE721485 10%)
}

.background_multi_gradient_linear_five {
    background: repeating-linear-gradient(45deg, #FFB534 5%, #FFB53485 10%), repeating-linear-gradient(-45deg, #FFB534 5%, #FFB53485 10%)
}

.background_multi_gradient_linear_six {
    background: repeating-linear-gradient(45deg, #FF6D60 5%, #FF6D6085 10%), repeating-linear-gradient(-45deg, #FF6D60 5%, #FF6D6085 10%)
}

.background_multi_gradient_linear_seven {
    background: repeating-linear-gradient(45deg, #F35588 5%, #F3558885 10%), repeating-linear-gradient(-45deg, #F35588 5%, #F3558885 10%)
}

.background_multi_gradient_linear_eight {
    background: repeating-linear-gradient(45deg, #0F1123 5%, #0F112385 10%), repeating-linear-gradient(-45deg, #0F1123 5%, #0F112385 10%)
}

.background_multi_gradient_linear_nine {
    background: repeating-linear-gradient(45deg, #fe4a49 5%, #fe4a4985 10%), repeating-linear-gradient(-45deg, #fe4a49 5%, #fe4a4985 10%)
}

.background_multi_gradient_linear_ten {
    background: repeating-linear-gradient(45deg, #2ab7ca 5%, #2ab7ca85 10%), repeating-linear-gradient(-45deg, #2ab7ca 5%, #2ab7ca85 10%)
}

.background_multi_gradient_linear_eleven {
    background: repeating-linear-gradient(45deg, #fed766 5%, #fed76685 10%), repeating-linear-gradient(-45deg, #fed766 5%, #fed76685 10%)
}

.background_multi_gradient_linear_twelve {
    background: repeating-linear-gradient(45deg, #fe8a71 5%, #fe8a7185 10%), repeating-linear-gradient(-45deg, #fe8a71 5%, #fe8a7185 10%)
}

.background_multi_gradient_linear_thirteen {
    background: repeating-linear-gradient(45deg, #0e9aa7 5%, #0e9aa785 10%), repeating-linear-gradient(-45deg, #0e9aa7 5%, #0e9aa785 10%)
}

.background_multi_gradient_linear_fourteen {
    background: repeating-linear-gradient(45deg, #009688 5%, #00968885 10%), repeating-linear-gradient(-45deg, #009688 5%, #00968885 10%)
}

.background_multi_gradient_linear_fifteen {
    background: repeating-linear-gradient(45deg, #54b2a9 5%, #54b2a985 10%), repeating-linear-gradient(-45deg, #54b2a9 5%, #54b2a985 10%)
}

.background_multi_gradient_linear_sixteen {
    background: repeating-linear-gradient(45deg, #dcedc1 5%, #dcedc185 10%), repeating-linear-gradient(-45deg, #dcedc1 5%, #dcedc185 10%)
}

.background_multi_gradient_linear_seventeen {
    background: repeating-linear-gradient(45deg, #536878 5%, #53687885 10%), repeating-linear-gradient(-45deg, #536878 5%, #53687885 10%)
}

.background_multi_gradient_linear_eighteen {
    background: repeating-linear-gradient(45deg, #6e7f80 5%, #6e7f8085 10%), repeating-linear-gradient(-45deg, #6e7f80 5%, #6e7f8085 10%)
}

.background_multi_gradient_linear_ninteen {
    background: repeating-linear-gradient(45deg, #854442 5%, #85444285 10%), repeating-linear-gradient(-45deg, #854442 5%, #85444285 10%)
}

.background_multi_gradient_linear_twenty {
    background: repeating-linear-gradient(45deg, #3b5998 5%, #3b599885 10%), repeating-linear-gradient(-45deg, #3b5998 5%, #3b599885 10%)
}

.background_multi_gradient_linear_twentyone {
    background: repeating-linear-gradient(45deg, #0F1123 5%, #0F112385 10%), repeating-linear-gradient(-45deg, #0F1123 5%, #0F112385 10%)
}

.background_multi_gradient_linear_twentyone {
    background: repeating-linear-gradient(45deg, rgba(64, 64, 64, 0.52) 5%, rgba(221, 221, 221, 0.49) 10%), repeating-linear-gradient(-45deg, rgba(64, 64, 64, 0.52) 5%, rgba(221, 221, 221, 0.49) 10%)
}

.background_multi_gradient_linear_radial_one {
    background: -webkit-repeating-radial-gradient(top left, circle, #3B3B9E 5%, #3B3B9E85 10%), repeating-linear-gradient(-45deg, #3B3B9E 5%, #3B3B9E85 10%)
}

.background_multi_gradient_linear_radial_two {
    background: -webkit-repeating-radial-gradient(top left, circle, rgba(4, 71, 52, 1) 5%, rgba(4, 71, 52, 0.7) 10%), repeating-linear-gradient(-45deg, rgba(4, 71, 52, 1) 5%, rgba(4, 71, 52, 0.7) 10%)
}

.background_multi_gradient_linear_radial_three {
    background: -webkit-repeating-radial-gradient(top left, circle, #88AB8E 5%, #88AB8E85 10%), repeating-linear-gradient(-45deg, #88AB8E 5%, #88AB8E85 10%)
}

.background_multi_gradient_linear_radial_four {
    background: -webkit-repeating-radial-gradient(top left, circle, #EE7214 5%, #EE721485 10%), repeating-linear-gradient(-45deg, #EE7214 5%, #EE721485 10%)
}

.background_multi_gradient_linear_radial_five {
    background: -webkit-repeating-radial-gradient(top left, circle, #FFB534 5%, #FFB53485 10%), repeating-linear-gradient(-45deg, #FFB534 5%, #FFB53485 10%)
}

.background_multi_gradient_linear_radial_six {
    background: -webkit-repeating-radial-gradient(top left, circle, #FF6D60 5%, #FF6D6085 10%), repeating-linear-gradient(-45deg, #FF6D60 5%, #FF6D6085 10%)
}

.background_multi_gradient_linear_radial_seven {
    background: -webkit-repeating-radial-gradient(top left, circle, #F35588 5%, #F3558885 10%), repeating-linear-gradient(-45deg, #F35588 5%, #F3558885 10%)
}

.background_multi_gradient_linear_radial_eight {
    background: -webkit-repeating-radial-gradient(top left, circle, #0F1123 5%, #0F112385 10%), repeating-linear-gradient(-45deg, #0F1123 5%, #0F112385 10%)
}


.background_multi_gradient_linear_radial_nine {
    background: -webkit-repeating-radial-gradient(top left, circle, #fe4a49 5%, #fe4a4985 10%), repeating-linear-gradient(-45deg, #fe4a49 5%, #fe4a4985 10%)
}

.background_multi_gradient_linear_radial_ten {
    background: -webkit-repeating-radial-gradient(top left, circle, #2ab7ca 5%, #2ab7ca85 10%), repeating-linear-gradient(-45deg, #2ab7ca 5%, #2ab7ca85 10%)
}

.background_multi_gradient_linear_radial_eleven {
    background: -webkit-repeating-radial-gradient(top left, circle, #fed766 5%, #fed76685 10%), repeating-linear-gradient(-45deg, #fed766 5%, #fed76685 10%)
}

.background_multi_gradient_linear_radial_twelve {
    background: -webkit-repeating-radial-gradient(top left, circle, #fe8a71 5%, #fe8a7185 10%), repeating-linear-gradient(-45deg, #fe8a71 5%, #fe8a7185 10%)
}

.background_multi_gradient_linear_radial_thirteen {
    background: -webkit-repeating-radial-gradient(top left, circle, #0e9aa7 5%, #0e9aa785 10%), repeating-linear-gradient(-45deg, #0e9aa7 5%, #0e9aa785 10%)
}

.background_multi_gradient_linear_radial_fourteen {
    background: -webkit-repeating-radial-gradient(top left, circle, #009688 5%, #00968885 10%), repeating-linear-gradient(-45deg, #009688 5%, #00968885 10%)
}

.background_multi_gradient_linear_radial_fifteen {
    background: -webkit-repeating-radial-gradient(top left, circle, #54b2a9 5%, #54b2a985 10%), repeating-linear-gradient(-45deg, #54b2a9 5%, #54b2a985 10%)
}

.background_multi_gradient_linear_radial_sixteen {
    background: -webkit-repeating-radial-gradient(top left, circle, #dcedc1 5%, #dcedc185 10%), repeating-linear-gradient(-45deg, #dcedc1 5%, #dcedc185 10%)
}

.background_multi_gradient_linear_radial_seventeen {
    background: -webkit-repeating-radial-gradient(top left, circle, #536878 5%, #53687885 10%), repeating-linear-gradient(-45deg, #536878 5%, #53687885 10%)
}

.background_multi_gradient_linear_radial_eighteen {
    background: -webkit-repeating-radial-gradient(top left, circle, #6e7f80 5%, #6e7f8085 10%), repeating-linear-gradient(-45deg, #6e7f80 5%, #6e7f8085 10%)
}

.background_multi_gradient_linear_radial_ninteen {
    background: -webkit-repeating-radial-gradient(top left, circle, #854442 5%, #85444285 10%), repeating-linear-gradient(-45deg, #854442 5%, #85444285 10%)
}

.background_multi_gradient_linear_radial_twenty {
    background: -webkit-repeating-radial-gradient(top left, circle, #3b5998 5%, #3b599885 10%), repeating-linear-gradient(-45deg, #3b5998 5%, #3b599885 10%)
}

.background_multi_gradient_linear_radial_twentyone {
    background: -webkit-repeating-radial-gradient(top left, circle, #0F1123 5%, #0F112385 10%), repeating-linear-gradient(-45deg, #0F1123 5%, #0F112385 10%)
}

.background_conical_one {
    background: conic-gradient(from 135deg, #ff0000cf, orange, yellow, #008000d9, #0f0fd5);
}

.background_pie_one {
    background: conic-gradient(#FF6D60 120deg, #88AB8E 120deg, #88AB8E 240deg, #3B3B9E 240deg);
}

.background_pie_two {
    background: conic-gradient(#fe4a49 120deg, #fed766 120deg, #fed766 240deg, #2ab7ca  240deg);
}

.background_rainbow_one {
    background: -webkit-radial-gradient(bottom center, circle, violet 12%, indigo 12%, indigo 26%, blue 25%, blue 39%, green 39%, green 52%, yellow 52%, yellow 62%, orange 59%, orange 71%, red 68%, red 80%, white 79%, white 92%);

    &.btn {
        background: violet;
    }
}

.background_rainbow_two {
    background: -webkit-radial-gradient(bottom center, circle, violet 12%, #4b0082bf 12%, #4b0082e3 26%, #0000ffd4 25%, #0000ffb3 39%, #008000cc 39%, #008000b8 52%, #ffff009c 52%, #ffff00ab 62%, #ffa500de 59%, orange 71%, #ff0000c4 68%, #ff0000b3 80%, white 79%, white 92%);

    &.btn {
        background: violet;
    }
}