/**
  Profile wave types
  generated from https://www.shapedivider.app/
  generte png by using https://app.haikei.app/
  generated alternate https://css-generators.com/wavy-shapes/
  **/
@import "../constant.scss";

.profile-three , .profile-four, .wave-card {
    .shape-fill {
        fill: $color_two !important;
    
    }
}

.waves {
    width: 100%;
    overflow: hidden;
    line-height: 0;
    margin-bottom: -1px;

    svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        margin-bottom: -1px;
    }

    .shape-fill {
        fill: $color_one;
    }
    
}

.wave-one {
    svg {
        height: 60px;
    }
}

.wave-two {
    transform: rotate(180deg);

    svg {
        height: 50px;
        transform: rotateY(180deg);
    }
}

.wave-three {
    transform: rotate(180deg);

    svg {

        height: 79px;
        transform: rotateY(180deg);
    }
}

.wave-four {
    transform: rotate(180deg);

    svg {
        height: 108px;
        transform: rotateY(180deg);
    }
}

.wave-five {
    svg {

        height: 123px;
        transform: rotateY(180deg);
    }
}

.wave-six {

    svg {

        height: 80px;
        transform: rotateY(180deg);
    }
}

.wave-seven {
    transform: rotate(180deg);

    svg {
        height: 76px
    }
}

.wave-eight {

    svg {

        height: 76px
    }
}

.wave-nine {

    svg {

        height: 76px;
        transform: rotateY(180deg);
    }
}

.wave-ten {
    transform: rotate(180deg);

    svg {
        height: 80px;
        transform: rotateY(180deg);
    }
}

