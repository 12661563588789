@import '../constant.scss';

.profile-link-card-image-small {
  width: 170px;
  height: 170px;
  margin: 3em auto 0em;
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 700px) {
    width: 100px !important;
    height: 100px;
  }
}

.profile-link-card-image-small-one {
  @extend .profile-link-card-image-small;
}

.profile-link-card-image-small-two {
  @extend .profile-link-card-image-small;
  border: 4px solid $color_two;
}

.profile-link-card-image-small-three {
  @extend .profile-link-card-image-small;
  border: 4px solid $color_three;
}

.profile-link-card-image-small-four {
  @extend .profile-link-card-image-small;
  border: 8px solid $color_two;
}

.profile-link-card-image-small-five {
  @extend .profile-link-card-image-small;
  border: 8px solid $color_three;
}

.profile-link-card-image-medium {
  width: 50%;
  height: 40vh;
  margin: 3em auto 1em;
  background-size: cover;
  background-position: center;
  
  @media only screen and (max-width: 700px) {
    width: calc(100% - 3em);
  }
}

.profile-link-card-image-medium-one {
  @extend .profile-link-card-image-medium;
}

.profile-link-card-image-medium-two {
  @extend .profile-link-card-image-medium;
  border: 4px solid $color_two;
}

.profile-link-card-image-medium-three {
  @extend .profile-link-card-image-medium;
  border: 4px solid $color_three;
}

.profile-link-card-image-medium-four {
  @extend .profile-link-card-image-medium;
  border: 8px solid $color_two;
}

.profile-link-card-image-medium-five {
  @extend .profile-link-card-image-medium;
  border: 8px solid $color_three;
}


.profile-link-card-image-large {
  width: 100%;
  height: 40vh;
  // margin: 2em auto;
  background-size: cover;
  background-position: center;
}

.profile-link-card-image-large-one {
  @extend .profile-link-card-image-large;
}

.profile-link-card-image-large-two {
  @extend .profile-link-card-image-large;
  border: 4px solid $color_two;
}

.profile-link-card-image-large-three {
  @extend .profile-link-card-image-large;
  border: 4px solid $color_three;
}

.profile-link-card-image-large-four {
  @extend .profile-link-card-image-large;
  border: 8px solid $color_two;
}

.profile-link-card-image-large-five {
  @extend .profile-link-card-image-large;
  border: 8px solid $color_three;
}
