@import './constant.scss';

.search-input {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  height: 32px;

  i {
    width: 32px;
    height: 32px;
    padding: 2px;
    text-align: center;
    padding-top: 5px;
    color: $color_two;
  }

  .form-control {
    border: none;
    width: 0px;
    padding: 0px;
    transition: all 0.35s, border-radius 0s;
    height: 32px;
    font-size: 12px;

    &:focus {
      box-shadow: none;
    }
  }

  &:hover {
    border: 1px solid #dee2e6;
    .form-control {
      width: 150px;
      padding: .375rem .75rem;
    }
    i {
      background: $color_two !important;
      color: $color_three;
    }
    @media only screen and (max-width: 400px)  {
      .form-control {
        width: 100px;
      }
    };
  }

}
