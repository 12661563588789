$color_one: #f5f5f6; // grey white
$color_two:  #FFF; //white
$color_three: #36344D; // light grey black for fonts
$color_five: rgb(202, 43, 78); // red

$color_seven: rgb(69 90 100 / 1); // border
$color_eight: rgb(69 90 100 / 0.3); // border

$color_nine: rgb(69 90 100 / 1); //custom input color
$color_ten: rgb(69 90 100 / 0.5); //custom input color

$color_eleven: #a2a5b9; //helper text
$color_twelve: #e1e3ec; //box shadow


$color_fifteen: #283d50; // website header
$color_sixteen: #556877; // website body

$color_seventeen: #ecf5ff; // website body

//Link card designs
$color_fourteen: black; // transperant

$color_fifteen: #36344dba;



